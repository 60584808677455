<template>
  <div id="app">
    <headerComp></headerComp>
    <router-view :key="key"></router-view>
    <footerComp></footerComp>
  </div>
</template>

<script>
import headerComp from "@/components/headerComp";
import footerComp from "@/components/footerComp.vue";
export default {
  name: "App",
  computed: {
    key() {
      return this.$route.fullPath;
    }
  },
  components: {
    headerComp,
    footerComp
  }
};
</script>

<style lang="less">
@import "styles/reset.css";
@import "styles/globalStyle.less";
#app {
  display: flex;
  flex-direction: column;
  .container {
    flex: 1;
  }
}
</style>