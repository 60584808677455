/*
 * @Author: baihuawei
 * @Date: 2022-08-04 13:30:25
 * @FilePath: /linkzo_officialwebsite/src/utils/request.js
 * @Modify: 
 * @Description: 
 */
import axios from "axios";
import {getCookie} from "@/utils/index";

const newInstance = axios.create({
    baseURL: process.env.VUE_APP_REQUEST_URL,
    // baseURL: "http://81.70.91.88:30050",
    timeout: 5000
});

newInstance.interceptors.request.use((config) => {
    config.headers = Object.assign(config.headers, {
        Authtoken: getCookie('Authtoken'),
        LinkzoSource: 'mobile',
    });
    return config;
});

newInstance.interceptors.response.use((response) => {
    if (response && response.status === 200) {
        return {err: response.data.code !== 0, ...response.data};
    } else {
        return {err: false, code: response.status, msg: '网络请求出错！'};
    }
});

export default newInstance;